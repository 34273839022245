import React from "react"
import { Row, Col } from "reactstrap"
import Layout from "../../components/layout/layout"
import Content from "../../components/content/content"
import Header from "../../components/header/header"
import { useUserOrgs } from "../../hooks/use-user"
import { Card, CardHeader, CardContent } from "@material-ui/core"
import EchartsBar from "../../components/metrics/metrics-echarts/echarts-bar"
import { Loading } from "../../components/loading"

const ArtifactoryPage = () => {
  const { loading } = useUserOrgs()

  if (loading) {
    return <Loading />
  }

  const artifactoryData = [
    { value: 68, name: "1" },
    { value: 62, name: "2" },
    { value: 60, name: "3" },
    { value: 58, name: "4" },
    { value: 56, name: "5" },
    { value: 48, name: "6" },
    { value: 46, name: "7" },
    { value: 38, name: "8" },
    { value: 36, name: "9" },
    { value: 28, name: "10" },
  ]

  return (
    <Layout pageTitle="Artifacts Dashboard">
      <Header contentPage={true} className="contentPage">
        <h1 className="display-4 font-weight-bold">Artifacts Dashboard</h1>
      </Header>
      <Content>
        <Row>
          <Col>
            <Card>
              <CardHeader title="Upload Statistics Top 10"></CardHeader>
              <CardContent style={{ width: "100%", height: 260 }}>
                <EchartsBar barId="upload" barData={artifactoryData}></EchartsBar>
              </CardContent>
            </Card>
          </Col>
          <Col>
            <Card>
              <CardHeader title="Download Statistics Top 10"></CardHeader>
              <CardContent style={{ width: "100%", height: 260 }}>
                <EchartsBar barId="download" barData={artifactoryData}></EchartsBar>
              </CardContent>
            </Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}

export default ArtifactoryPage
